<template>
	<div class="modal-overlay"
		@click="toggleBodyClass(false), $emit('close-modal')"
	>
		<div style="margin: auto;max-width: 100%;">
			<div class="modal delivery-modal" @click.stop>
				<div class="modal__close d-flex block-center transition"
					@click="toggleBodyClass(false), $emit('close-modal')"
				>
					<span class="icon-close transition"></span>
				</div>
				<div class="modal__inner d-flex flex-wrap">
					<h2 class="modal__title">Как вы хотите получить заказ?</h2>

					<div class="delivery-modal__types d-flex justify-between">
						<div class="delivery-modal__types-item d-flex block-center transition noselect"
							:class="{ 'is-active': showDelivery }"
							@click="showDelivery = true"
						>
							Доставка
						</div>
						<div class="delivery-modal__types-item d-flex block-center transition noselect"
							:class="{ 'is-active': !showDelivery }"
							@click="showDelivery = false"
						>
							Самовывоз
						</div>
					</div>

					<div class="delivery-type" v-show="showDelivery">
						<span class="delivery-modal__text">Укажите ваш адрес доставки в городе Казань</span>

						<div class="delivery-modal__error" v-if="errorMessage">
							{{ errorMessage }}
						</div>
						<div class="delivery-modal__error"
							v-if="canSendDeliveryPointError === true"
						>
							К сожалению, доставка по этому адресу не осуществляется
							- расстояние до ближайшего ресторана больше чем его
							максимальная зона доставки.<br />
							Введите другой адрес или выберите самовывоз.
						</div>

						<div class="form">
							<div class="form-fields d-flex flex-wrap">
								<div class="form-field is-error el-col-24">
									<label class="form-field__inner" ref="address_line">
										<input type="text" class="form-field__inner-input" placeholder="Адрес доставки"
											ref="suggestInput"
											v-model="getDeliveryPoint.address_line"
											@keyup="getDeliveryInfo()"
										/>
										<span class="form-field__inner-label">Город, улица, дом</span>
									</label>
								</div>
								<div class="form-field el-col-8">
									<label class="form-field__inner">
										<input type="text" class="form-field__inner-input" placeholder="Кв/оф"
											v-model="getDeliveryPoint.flat"
										/>
										<span class="form-field__inner-label">Кв/оф</span>
									</label>
								</div>
								<div class="form-field el-col-8">
									<label class="form-field__inner">
										<input type="text" class="form-field__inner-input" placeholder="Домофон"
											v-model="getDeliveryPoint.doorphone"
										/>
										<span class="form-field__inner-label">Домофон</span>
									</label>
								</div>
								<div class="form-field el-col-8">
									<label class="form-field__inner">
										<input type="text" class="form-field__inner-input" placeholder="Подъезд"
											v-model="getDeliveryPoint.entrance"
										/>
										<span class="form-field__inner-label">Подъезд</span>
									</label>
								</div>
							</div>
						</div>

						<div class="modal__buttons d-flex flex-wrap">
							<button class="modal__buttons-btn modal__buttons-btn--yellow d-flex block-center transition"
								@click="checkNewMenuDelivery()"
								:disabled="canSendDeliveryPoint === false || errorMessage"
							>
								Сохранить
							</button>
							<a href="" class="modal__buttons-btn modal__buttons-btn--white d-flex block-center transition"
								v-if="this.getToken !== null"
								@click.prevent="toggleBodyClass(false), $emit('close-modal'), toTop(), $router.push('/personal-account')"
							>
								Войти в профиль
							</a>
							<a href="" class="modal__buttons-btn modal__buttons-btn--white d-flex block-center transition"
								v-else
								@click.prevent="$emit('close-modal'), $emit('show-login-modal')"
							>
								Войти
							</a>
						</div>
					</div>

					<div class="pickup-type" v-show="!showDelivery">
						<span class="delivery-modal__text">
							Выберите точку самовывоза <br />
							<span>Вы сможете заказать только меню из выбранного ресторана.</span>
						</span>

						<div class="pickup-addresses d-flex flex-wrap">
							<label class="pickup-addresses__item d-flex transition noselect"
								v-for="(item, index) in pickupOrganizations"
								:key="index"
							>
								<input type="radio" class="pickup-addresses__item-input"
									:value="item.id"
									name="pickup-address"
									v-model="address"
								/>
								<div class="pickup-addresses__item-inner d-flex items-center transition">
									<div class="pickup-addresses__item-check d-flex block-center transition">
										<span class="icon-check"></span>
									</div>
									<div class="pickup-addresses__item-info d-flex flex-column">
										<span class="pickup-addresses__item-title transition">{{ item.name }}</span>
										<span class="pickup-addresses__item-schedule transition"
											v-if="getWorkDay(item.work_days)"
										>
											{{ getWorkDay(item.work_days).end_time.slice(0, -3) }}
										</span>
									</div>
								</div>
							</label>
						</div>
						<div class="modal__buttons d-flex flex-wrap">
							<button class="modal__buttons-btn modal__buttons-btn--yellow d-flex block-center transition"
								@click="checkNewMenuPickup()"
							>
								Сохранить
							</button>
							<a href="" class="modal__buttons-btn modal__buttons-btn--white d-flex block-center transition"
								v-if="this.getToken !== null"
								@click.prevent="toggleBodyClass(false), $emit('close-modal'), toTop(), $router.push('/personal-account')"
							>
								Войти в профиль
							</a>
							<a href="" class="modal__buttons-btn modal__buttons-btn--white d-flex block-center transition"
								v-else
								@click.prevent="$emit('close-modal'), $emit('show-login-modal')"
							>
								Войти
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { api } from '@/api';
import { mapActions, mapState } from 'pinia';
import { useUserStore } from '@/store/user.js';
import { useOrderStore } from '@/store/order.js';
import { useOrganizationStore } from '@/store/organization.js';
import _ from 'lodash';

export default {
	name: 'DeliveryModal',
	// mixins: [CategoryMixin],
	props: {
		id: {
			required: true,
		},
	},
	data() {
		return {
			showDelivery: true,
			address: null, // this.getDeliveryAddress,
			deliveryAddress: null,
			day: (new Date().getDay() + 6) % 7,
			suggestView: null,
			selectedCoordinates: null,
			shortestRoute: null,
			pickupOrganizations: [],
			deliveryOrganizations: [],
			deliveryOrganization: null,
			typeDelivery: 'Самовывоз',
			zonesMaxDistance: null,
			canSendDeliveryPoint: false,
			canSendDeliveryPointError: false,
			delivery: {
				cost: null,
				freeCost: null,
				minCost: null,
				minutes: null,
			},
			deliveryZones: [],
			currentZone: null,
			errorMessage: null,
			newMenu: [],
			newTypeDelivery: [],
		};
	},
	created() {
		this.fetchPickupOrganizations();
		this.fetchDeliveryOrganizations();
	},
	computed: {
		...mapState(useUserStore, [
			'getDeliveryAddress',
			'getDeliveryPoint',
			'getUserId',
			'getUser',
			'getToken',
		]),
		...mapState(useOrganizationStore, ['getOrganizations']),
		...mapState(useOrderStore, ['getMenuId', 'getItemsCount', 'getItems']),
	},
	mounted() {
		this.address = this.getDeliveryAddress;

		const script = document.createElement('script');
		script.async = true;
		script.src =
			'https://api-maps.yandex.ru/2.1/?apikey=6f7aaae5-67a3-4b07-8897-cf58d3e92906&lang=en_US&suggest_apikey=ea38fdf6-893b-4a4d-aff8-a35d247113ec';
		document.getElementsByTagName('head')[0].appendChild(script);

		// Make cp available globally
		script.onload = () => {
			window.ymaps.ready(this.initAutocomplete);
		};
		if (this.getDeliveryPoint.address_line) {
			this.canSendDeliveryPoint = true;
		}
	},
	methods: {
		...mapActions(useUserStore, [
			'setDeliveryPoint',
			'setDeliveryAddress',
			'setDeliveryName',
			'setDistance',
			'setDeliveryType',
			'setDelivery',
		]),
		...mapActions(useOrderStore, ['setMenu']),
		getWorkDay(work_days) {
			let weekday = (new Date().getDay() + 6) % 7;
			return work_days.find((work_day) => work_day.weekday === weekday);
		},

		getDeliveryInfo() {
			let locality = 'Казань';

			let address_line =
				locality + ',' + this.getDeliveryPoint.address_line;

			if (this.getDeliveryPoint.address_line) {
				window.ymaps.geocode(address_line).then((res) => {
					let firstGeoObject = res.geoObjects.get(0),
						error;

					if (firstGeoObject) {
						// Об оценке точности ответа геокодера можно прочитать тут: https://tech.yandex.ru/maps/doc/geocoder/desc/reference/precision-docpage/
						switch (
							firstGeoObject.properties.get('metaDataProperty.GeocoderMetaData.precision')) {
							case 'exact':
								break;
							case 'number':
							case 'near':
							case 'range':
								error = 'Уточните номер дома';
								break;
							case 'street':
								error = 'Уточните номер дома';
								break;
							case 'other':
							default:
								error = 'Уточните адрес';
						}
					} else {
						error = 'Адрес не найден';
					}
					// Если геокодер возвращает пустой массив или неточный результат, то показываем ошибку.
					if (error) {
						this.canSendDeliveryPoint = false;
						this.errorMessage = error;
					} else {
						this.canSendDeliveryPoint = true;
						this.errorMessage = null;
					}

					let coordinates = firstGeoObject.geometry.getCoordinates();

					// Do something with the coordinates
					// For example, updating a data property or placing a marker on the map
					this.selectedCoordinates = coordinates;
					console.log('this.selectedCoordinates - ', this.selectedCoordinates);
					this.findNearestRoute();
				});
			}
		},
		getZonesMaxDistance(organizationId) {
			this.deliveryOrganization = this.getOrganizations.find(
				(organization) => organization.id === organizationId
			);
			if (this.deliveryOrganization.zones.length > 0)
			this.zonesMaxDistance = _.maxBy(this.deliveryOrganization.zones, 'distance').distance;
		},
		getCurrentZone() {
			this.deliveryZones = _.sortBy(this.deliveryOrganization.zones, 'distance');

			for (const zone of this.deliveryZones) {
				if (zone.distance > this.shortestRoute.distance) {
					this.currentZone = zone;
					break;
				}
			}

			this.delivery.cost = this.currentZone.delivery_cost;
			this.delivery.freeCost = this.currentZone.delivery_free_cost;
			this.delivery.minCost = this.currentZone.delivery_min_cost;
			this.delivery.minutes = this.currentZone.delivery_minutes;
			this.setDelivery(this.delivery);
		},
		toggleBodyClass(val) {
			document.body.classList.toggle('hidden', val);
		},
		fetchPickupOrganizations() {
			this.pickupOrganizations = this.getOrganizations.filter(
				(organization) => {
					return organization.delivery_types.filter((type) => type.type === 2);
				}
			);
		},
		fetchDeliveryOrganizations() {
			this.deliveryOrganizations = this.getOrganizations.filter(
				(organization) => {
					return organization.delivery_types.filter((type) => type.type === 1);
				}
			);
		},
		save() {
			// this.setMenu(this.address);
			this.addMenuId(this.address);
			this.setDeliveryAddress(this.address);
			const deliveryName = this.getOrganizations.find((category) => category.id === this.address);
			this.setDeliveryName(deliveryName.name);
			this.typeDelivery = 'Самовывоз';
			this.setDeliveryType(this.typeDelivery);
			this.$emit('close-modal');
			// this.$emit('load-menu');
			this.toggleBodyClass(false);
		},
		initAutocomplete() {
			let locality = 'Казань';
			this.suggestView = new window.ymaps.SuggestView(
				this.$refs.suggestInput,
				{
					provider: {
						suggest: function (request) {
							return window.ymaps.suggest(
								locality + ', ' + request
							);
						},
					},
				}
			);

			// Handle the selected suggestion
			this.suggestView.events.add('select', this.onSuggestionSelected);
		},
		onSuggestionSelected(e) {
			const selected = e.get('item');
			// Handle the selected suggestion
			this.getDeliveryPoint.address_line = selected.displayName;
			// Use Yandex Maps geocoding to get coordinates
			window.ymaps
				.geocode(this.getDeliveryPoint.address_line)
				.then((res) => {
					let firstGeoObject = res.geoObjects.get(0);
					let coordinates = firstGeoObject.geometry.getCoordinates();

					// Do something with the coordinates
					// For example, updating a data property or placing a marker on the map
					this.selectedCoordinates = coordinates;
					this.errorMessage = null;
					console.log(
						'this.selectedCoordinates - ',
						this.selectedCoordinates
					);
					this.findNearestRoute();
				});
		},
		findNearestRoute() {
			let promises = [];

			this.deliveryOrganizations.forEach((org) => {
				let promise = window.ymaps
					.route([
						this.selectedCoordinates.join(','),
						[
							parseFloat(org.latitude),
							parseFloat(org.longitude),
						].join(','),
					])
					.then((route) => {
						console.log('~coordinates', [
							org.name,
							route.getLength(),
						]);
						console.log('ROUTE', route);

						console.log(route.properties.get('distance'));
						return {
							distance: route.getLength(), // Get route length
							coordinates: [
								parseFloat(org.latitude),
								parseFloat(org.longitude),
							],
							name: org.name,
							id: org.id,
						};
					})
					.catch((e) => {
						console.log('Ymap Error', e);
					});
				promises.push(promise);
			});
			Promise.all(promises).then((results) => {
				results = results.filter((item) => item);
				console.log('results - ', results);
				this.shortestRoute = results.reduce((prev, current) => {
					if (prev) {
						if (prev.distance && current.distance) {return prev.distance < current.distance ? prev : current;}
					}
				});
				console.log('this.shortestRoute - ', this.shortestRoute);
			});
		},
		async sendDeliveryPoint() {
			if (this.canSendDeliveryPoint === true) {
				let locality = 'Казань';
				let locality1 = 'Республика Татарстан';

				if (
					this.getDeliveryPoint.address_line.indexOf(locality) === -1
				) {
					this.getDeliveryPoint.address_line =
						this.getDeliveryPoint.address_line + ', ' + locality;
				}
				if (
					this.getDeliveryPoint.address_line.indexOf(locality1) === -1
				) {
					this.getDeliveryPoint.address_line =
						this.getDeliveryPoint.address_line + ', ' + locality1;
				}
				if (this.getToken !== null) {
					let userUpdated = await api.updateUserData({
						delivery_point: this.getDeliveryPoint
					}).then((response) => response.data);
					this.setDeliveryPoint(userUpdated.delivery_point);
				}

				this.typeDelivery = 'Доставка по адресу';
				this.setDeliveryType(this.typeDelivery);
				this.$emit('close-modal');
				this.toggleBodyClass(false);
				this.$emit('load-menu');
			}
		},
		checkNewMenuDelivery() {
			if (this.getItemsCount > 0) {
				this.newTypeDelivery = 'Доставка по адресу';
				this.getNewMenu(this.getDeliveryAddress);
			} else {
				this.sendDeliveryPoint();
				this.$router.push('/');
			}
		},
		checkNewMenuPickup() {
			if (this.getItemsCount > 0) {
				this.newTypeDelivery = 'Самовывоз';
				this.getNewMenu(this.address);
			} else {
				this.save();
				this.$router.push('/');
			}
		},
		async getNewMenu(newMenuID) {
			await api
				.getMenuItem(newMenuID, this.newTypeDelivery)
				.then((response) => {
					this.newMenu = response.data.categories;

					let productsNewMenu = this.newMenu.flatMap(
						(category) => category.products
					);
					let notExistProducts = [];

					this.getItems.forEach((item) => {
						let index = productsNewMenu.findIndex(
							(product) => product.id === item.product
						);

						if (index === -1) {
							notExistProducts.push(item);
						}
					});

					if (notExistProducts.length > 0) {
						this.$emit('close-modal');
						this.$emit(
							'show-not-exist-products-modal',
							notExistProducts,
							this.newTypeDelivery
						);
					} else {
						if (this.newTypeDelivery === 'Самовывоз') {
							this.save();
						} else {
							this.sendDeliveryPoint();
						}
					}
				});
		},
		async addMenuId(organizationId) {
			await api.getOrganization(organizationId).then((response) => {
				let menuId = response.data.menu_id;
				this.setMenu(menuId);
				this.$emit('load-menu');
			});
		},
		toTop() {
			window.scrollTo(0, 0);
		}
	},
	watch: {
		shortestRoute(val) {
			if (val) {
				this.getZonesMaxDistance(this.shortestRoute.id);

				if (this.shortestRoute.distance > this.zonesMaxDistance) {
					this.canSendDeliveryPoint = false;
					this.canSendDeliveryPointError = true;
					this.$refs.address_line.classList.add('is-error');
				} else {
					this.canSendDeliveryPoint = true;
					this.canSendDeliveryPointError = false;
					this.$refs.address_line.classList.remove('is-error');
					this.addMenuId(this.shortestRoute.id);
					// this.setMenu(this.shortestRoute.id);
					this.setDeliveryAddress(this.shortestRoute.id);
					this.setDeliveryName(this.shortestRoute.name);
					this.setDistance(this.shortestRoute.distance);
					// this.setDistance(this.shortestRoute.distance * 1.609344);
					// this.$emit('load-menu');
					this.getCurrentZone();
				}
			}
		},
	},
};
</script>
