<template>
    <div
            class="cart d-flex flex-wrap transition"
            :class="{ 'is-open': showCart }"
    >
        <div class="cart__overlay transition" @click="$emit('hide-cart'), toggleBodyClass(false)"></div>
        <div class="cart__content transition">
            <div class="cart-top d-flex items-center">
                <div class="cart-top__info d-flex flex-column">
                    <span class="cart-top__info-title">Моя корзина</span>
                    <span class="cart-top__info-count">
						{{ getItemsCount }}
						{{ inflectNumber(getItemsCount) }} на
						{{ getAmount() }} ₽
					</span>
                </div>
                <div class="cart-top__close d-flex block-center transition"
                     @click="$emit('hide-cart'), toggleBodyClass(false)"
                >
                    <span class="icon-close"></span>
                </div>
            </div>

            <div class="cart-inner d-flex flex-wrap" v-if="getItemsCount > 0">
                <div class="cart-inner__products">
                    <cart-product
                            v-for="product in getItems"
                            :key="`product-${product.id}`"
                            :product="product"
                    />
                </div>

                <div class="cart-inner__slider">
                    <span class="cart-inner__slider-title">Добавить к заказу?</span>

                    <cart-slider />
                </div>
            </div>

            <div class="cart-bottom d-flex flex-wrap" v-if="getItemsCount > 0">
                <input
                        type="text"
                        class="cart-bottom__promo transition"
                        placeholder="Промокод"
                />

                <div class="cart-bottom__details">
                    <div class="cart-bottom__detail d-flex items-center justify-between">
                        <span class="cart-bottom__detail-text">Товары ({{ getProductsAmount() }})</span>
                        <span class="cart-bottom__detail-text">
							<b>{{ getAmount() }} ₽</b>
						</span>
                    </div>
                    <!-- TODO END -->
                    <!-- <div class="cart-bottom__detail d-flex items-center justify-between">
                        <span class="cart-bottom__detail-text">Начислим баллы</span>
                        <span class="cart-bottom__detail-text"><b>+75</b></span>
                    </div> -->
                    <!-- TODO END -->
                    <div class="cart-bottom__detail d-flex items-center justify-between" v-if="getDeliveryType !== 'Самовывоз'">
                        <span class="cart-bottom__detail-text">Доставка по городу</span>
                        <span class="cart-bottom__detail-text" v-if="getAmount() >= getDelivery.freeCost"><b>Бесплатно</b></span>
                        <span class="cart-bottom__detail-text" v-else><b>{{ getDelivery.cost }} ₽</b></span>
                    </div>
                </div>

                <span class="cart-bottom__text" v-if="getDeliveryType === 'Доставка по адресу' && getDelivery.minCost >= getAmount()">Минимальная сумма товаров для оформления заказа {{ getDelivery.minCost }} ₽</span>

                <button
                        class="cart-bottom__button d-flex block-center transition"
                        @click="goToDeliveryOrder(), hideCart()"
                        :disabled="getDeliveryType === 'Доставка по адресу' && getDelivery.minCost >= getAmount()"
                >
                    Оформить заказ · {{ getAmount() + getDeliveryPrice() }} ₽
                </button>
            </div>

            <div class="cart-empty d-flex block-center" v-else>
                <div class="cart-empty__info d-flex flex-column">
                    <span class="cart-empty__info-text">Добавьте блюда</span>
                    <span class="cart-empty__info-title">в Вашей корзине <br />пока пусто</span>
                </div>
                <img
                        src="./../../assets/cart-empty.svg"
                        alt="cart empty image"
                        class="cart-empty__image"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import CartSlider from './CartSlider';
    import CartProduct from './CartProduct';
    import { mapActions } from 'pinia';
    import { useOrderStore } from '@/store/order.js';
    import { useUserStore } from '@/store/user.js';
    import { mapState } from 'pinia';
    import _ from 'lodash';

    export default {
        name: 'CartElement',
        props: {
            showCart: {
                type: Boolean,
            },
        },
        components: {
            CartSlider,
            CartProduct,
        },
        data() {
            return {
                // products: [],
            };
        },
        computed: {
            ...mapState(useOrderStore, ['getItems', 'getItemsCount']),
            ...mapState(useUserStore, ['getDelivery', 'getDeliveryType']),
        },
        created() {
            /*let products = this.getItems.map(item => item.product);
                if(products) {
                    this.fetchProductsDetails(products.join(','));
                }*/
        },
        methods: {
            /*async fetchProductsDetails(data) {
                    try {
                        const response = await api.getProductsDetails(data);
                        this.products = response.data;
                        console.log('this.products -', this.products);
                    } catch (error) {
                        console.error('Ошибка получения списка елементов:', error);
                    }
                },*/
            async sendOrder(amount) {
                return this.createOrder(amount);
            },
            ...mapActions(useOrderStore, [
                'plusAmount',
                'minusAmount',
                'createOrder',
                'setCurrentOrderId',
                'setCurrentNumber',
            ]),
            getAmount() {
                let amount = 0;
                let modifiersAmount = 0;
                let additionalPrice = 0;

                this.getItems.forEach((item) => {
                    amount += item.amount * parseFloat(item.price);

                    let selectedModifiers = item.modifiers.filter(
                        (modifier) => modifier.selected
                    );
                    selectedModifiers.forEach((modifier) => {
                        modifier.children.forEach((child) => {
                            if (modifier.childrenSelected.includes(child.id)) {
                                additionalPrice += (parseFloat(child.prices[0].current_price) * item.amount);
                            }
                        });
                    });
                    if (selectedModifiers) {
                        modifiersAmount += item.amount * parseFloat(_.sumBy(selectedModifiers, 'free_of_charge_amount'));
                    }
                });

                return amount + modifiersAmount + additionalPrice;
            },
            getDeliveryPrice() {
                if (this.getDeliveryType !== 'Самовывоз') {
                    if (this.getDelivery.freeCost > this.getAmount()) {
                        return this.getDelivery.cost;
                    } else {
                        return 0;
                    }
                } else {
                    return 0;
                }
            },
            getProductsAmount() {
                let amount = 0;
                this.getItems.forEach((item) => {
                    amount += item.amount;
                });
                return amount;
            },
            hideCart() {
                this.$emit('hide-cart');
                this.toggleBodyClass(false);
            },
            minusCount(id) {
                this.minusAmount(id);
            },
            addCount(id) {
                this.plusAmount(id);
            },
            toggleBodyClass(val) {
                document.body.classList.toggle('hidden', val);
            },
            inflectNumber(number) {
                let forms = ['блюдо', 'блюда', 'блюд'];
                number = Math.abs(number);
                let lastDigit = number % 10;
                let lastTwoDigits = number % 100;

                if (lastTwoDigits >= 11 && lastTwoDigits <= 20) {
                    return forms[2];
                } else if (lastDigit === 1 || lastDigit === 21) {
                    return forms[0];
                } else if (lastDigit >= 2 && lastDigit <= 4) {
                    return forms[1];
                } else {
                    return forms[2];
                }
            },
            goToDeliveryOrder() {
                this.$router.push('/delivery-order');
            }
        },
    };
</script>
